import React from "react";
import styled from "styled-components";
import Image from "react-shimmer";
import { Grid } from "@material-ui/core";

const CardWrapper = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 20px 0px;

  &:last-child {
    border: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  h5 {
    font-size: 16px;
    margin: 0;
  }

  h5.show-description,
  p.show-description {
    cursor: pointer;
  }

  p.show-description {
    color: blue;
    margin: 8px 0;
  }
`;

const ImageWrapper = styled.div`
  border: 1px solid #ccc;
  height: 36px;
  padding: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SchoolLogoCard = ({ schools = [] }) => {
  return (
    <CardWrapper>
      <Grid container spacing={2} justify="center" alignItems="center">
        {schools.map(({ schoolName, schoolLogoUrl }) => (
          <Grid item xs={2} key={schoolName}>
            <ImageWrapper>
              <Image
                src={schoolLogoUrl}
                width={130}
                height={36}
                alt={schoolName}
                style={{ objectFit: "cover" }}
              />
            </ImageWrapper>
          </Grid>
        ))}
      </Grid>
    </CardWrapper>
  );
};

export default SchoolLogoCard;
