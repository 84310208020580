import React from "react";

export default function withDelayUnmounting(Component) {
  return class extends React.Component {
    state = {
      shouldRender: this.props.isMounted,
      willUnmountNow: false
    };

    componentDidUpdate(prevProps) {
      if (prevProps.isMounted && !this.props.isMounted) {
        this.setState({ willUnmountNow: true }, () => {
          setTimeout(
            () => this.setState({ shouldRender: false }),
            this.props.delayTime
          );
        });
      } else if (!prevProps.isMounted && this.props.isMounted) {
        this.setState({ shouldRender: true, willUnmountNow: false });
      }
    }

    render() {
      const { shouldRender, willUnmountNow } = this.state;

      return shouldRender ? (
        <Component willUnmountNow={willUnmountNow} {...this.props} />
      ) : null;
    }
  };
}
