import * as types from "./types";

export const fetchLocationByZip = zip => ({
  type: types.FETCH_LOCATION_BY_ZIP,
  meta: {
    async: true,
    blocking: true,
    path: `/locations?zipCode=${zip}`,
    method: "GET",
    body: {}
  }
});
export const updateLocation = ({ name, value }) => ({
  type: types.UPDATE_LOCATION,
  payload: {
    name,
    value
  }
});

export const fetchMsaCodeByZip = zip => ({
  type: types.FETCH_MSA_CODE_BY_ZIP,
  meta: {
    async: true,
    blocking: true,
    path: `/locations/msa?zipCode=${zip}`,
    method: "GET",
    body: {}
  }
});
