export const isDefined = (ref, path) => {
  let name;
  let keys = path.split(".");

  //check if the reference object exists or not
  if (!ref || typeof ref !== "object") return false;

  while ((name = keys.shift())) {
    if (!ref.hasOwnProperty(name)) return false;
    ref = ref[name];
  }
  return true;
};
