import React from "react";
import styled from "styled-components";

const Wrapper = styled.p`
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 11px;
  text-align: right;

  &.disabled {
    pointer-events: none;
  }

  span {
    cursor: pointer;
    color: #999;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PreviousButton = ({ className, children, ...props }) => (
  <Wrapper className={className}>
    <span {...props}>{children}</span>
  </Wrapper>
);

export default PreviousButton;
