export const CREATE_LEAD = "lead/create";
export const CREATE_LEAD_DONE = "lead/create_done";
export const CREATE_LEAD_FAILED = "lead/create_failed";

export const UPDATE_LEAD = "lead/update";
export const UPDATE_LEAD_DONE = "lead/update_done";
export const UPDATE_LEAD_FAILED = "lead/update_failed";

export const COMPLETE_LEAD = "lead/complete_lead";
export const COMPLETE_LEAD_DONE = "lead/complete_lead_done";
export const COMPLETE_LEAD_FAILED = "lead/complete_lead_failed";
