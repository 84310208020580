import * as types from "./types";

const initialState = {
  isFetchingLocationByZip: false,
  fetchLocationByZipDone: false,
  fetchLocationByZipFailed: false,
  isFetchingMsaByZip: false,
  fetchMsaByZipDone: false,
  fetchMsaByZipFailed: false,
  msaData: {}
};

const locationReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_LOCATION_BY_ZIP:
      return {
        ...state,
        isFetchingLocationByZip: true,
        fetchLocationByZipDone: false,
        fetchLocationByZipFailed: false
      };
    case types.FETCH_LOCATION_BY_ZIP_DONE:
      return {
        ...state,
        isFetchingLocationByZip: false,
        fetchLocationByZipDone: true,
        fetchLocationByZipFailed: false,
        locationData: payload.data
      };
    case types.FETCH_LOCATION_BY_ZIP_FAILED:
      return {
        ...state,
        isFetchingLocationByZip: false,
        fetchLocationByZipDone: false,
        fetchLocationByZipFailed: true
      };
    case types.FETCH_MSA_CODE_BY_ZIP:
      return {
        ...state,
        isFetchingMsaByZip: true,
        fetchMsaByZipDone: false,
        fetchMsaByZipFailed: false
      };
    case types.FETCH_MSA_CODE_BY_ZIP_DONE:
      return {
        ...state,
        isFetchingMsaByZip: false,
        fetchMsaByZipDone: true,
        fetchMsaByZipFailed: false,
        msaData: payload.data || {}
      };
    case types.FETCH_MSA_CODE_BY_ZIP_FAILED:
      return {
        ...state,
        isFetchingMsaByZip: false,
        fetchMsaByZipDone: false,
        fetchMsaByZipFailed: true
      };
    case types.UPDATE_LOCATION:
      return {
        ...state,
        locationData: { ...state.locationData, [payload.name]: payload.value }
      };
    default:
      return state;
  }
};

export default locationReducers;
