import styled from "styled-components";
import { typography, space, color } from "styled-system";
import { Button } from "@material-ui/core";

const CustomButton = styled(Button)`
  && {
    ${space};
    ${color};
    span.MuiButton-label {
        ${typography};
      }
    }
  }
`;

export default CustomButton;
