import React from "react";
import styled from "styled-components";

const DiscloserText = styled.span`
  a {
    color: #000;
  }
`;

const CCPAText = ({ url }) => {
  return (
    <DiscloserText>
      We will collect your contact information to respond to your requests for
      information and provide you with educational matching services. As a
      result, you may be matched to one or more sponsored schools from which we
      receive compensation. Compensation may impact where Sponsored Schools
      appear on our websites. We use cookies and other tracking technologies to
      collect your personal information in order to gauge online activity on our
      website, measure the effectiveness of online services, applications, and
      tools. For more information, see our{" "}
      <a href={url} target="_blank" rel="noreferrer noopener">
        privacy policy.
      </a>
    </DiscloserText>
  );
};

export default CCPAText;
