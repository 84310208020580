import * as types from "./types";

const initialState = {
  emailData: {}
};

const validationReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.VALIDATE_EMAIL:
      return {
        ...state,
        isValidatingEmail: true,
        isValidatingEmailDone: false,
        isValidatingEmailFailed: false
      };
    case types.VALIDATE_EMAIL_DONE:
      return {
        ...state,
        isValidatingEmail: false,
        isValidatingEmailDone: true,
        isValidatingEmailFailed: false,
        emailData: payload.data
      };
    case types.VALIDATE_EMAIL_FAILED:
      return {
        ...state,
        isValidatingEmail: false,
        isValidatingEmailDone: false,
        isValidatingEmailFailed: true
      };

    default:
      return state;
  }
};

export default validationReducers;
