import Cookies from "js-cookie";

import { v4 as uuidV4 } from "uuid";

const createCookie = () => {
  const uuid = uuidV4();

  if (Cookies.get("userCookie") === undefined) {
    Cookies.set("userCookie", uuid, {
      expires: 365
    });
  }
  return uuid;
};

const getCookie = () => {
  if (Cookies.get("userCookie") !== undefined) {
    return Cookies.get("userCookie");
  } else {
    const userCookie = createCookie();
    return userCookie;
  }
};

export { createCookie, getCookie };
