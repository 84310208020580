import * as types from "./types";

export const fetchWidgetById = id => ({
  type: types.FETCH_WIDGET_BY_ID,
  meta: {
    async: true,
    blocking: true,
    path: `/widgets/${id}`,
    method: "GET",
    body: {}
  }
});
