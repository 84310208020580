import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Typography, Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { createGTMEvent } from "../../../utils/googleTagManager";
import {
  FormBodyContainer,
  FormHeaderContainer,
  Indicator,
  CheckboxField,
  CustomTypography
} from "../../components";
import SchoolLogoCard from "./SchoolLogoCard";
import ClickoutsSchoolCard from "./ClickoutsSchoolCard";
import { matchActions } from "../../../state/match";
import { questionActions } from "../../../state/question";
import { leadActions } from "../../../state/lead";
import { trackActions } from "../../../state/track";
import {
  defaultConsentText,
  getConsentTextForMatches,
  defaultClickoutsMatches,
  pixelEventName,
  eddyClicksMatches
} from "../../../config";

class MatchStageTwo extends Component {
  state = {
    isTcpaDisclosureChecked: false,
    isClickSearchStarted: false,
    clicksCountEventSent: false
  };

  componentDidMount() {
    const { searchClickoutsMatches, searchData, leadData, trackPixel } =
      this.props;

    window.scrollTo(0, 0);

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4 Search Stage Two",
      action: "Load"
    });

    trackPixel({
      step: "4",
      eventName: pixelEventName.pageView,
      leadData
    });

    searchClickoutsMatches({
      ...searchData,
      selectedCategoryIds: leadData.selectedCategoryIds
    });
    this.setState({ isClickSearchStarted: true });
  }

  componentDidUpdate() {
    const {
      isSearchingClickoutsMatches,
      searchClickoutsMatchesDone,
      clickoutsMatches,
      leadData
    } = this.props;
    const { clicksCountEventSent } = this.state;

    if (
      !isSearchingClickoutsMatches &&
      searchClickoutsMatchesDone &&
      !clicksCountEventSent
    ) {
      createGTMEvent({
        event: "Clickouts Search",
        label: leadData.id,
        action: "Click",
        category: "ClickoutsMatchFound",
        value: clickoutsMatches.schools ? clickoutsMatches.schools.length : 0
      });
      this.setState({ clicksCountEventSent: true });
    }
  }

  onNextStepButtonClicked = data => {
    const { goToNextStep, values } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4 Next Button",
      action: "Click"
    });

    goToNextStep(values);
  };

  getSchoolNames = matches => {
    let schools = "";
    for (var i = 0; i < matches.length; i++) {
      if (i !== matches.length - 1) {
        schools += `${matches[i].schoolName}; `;
      } else {
        schools += `${matches[i].schoolName}`;
      }
    }
    return schools;
  };

  onTcpaDisclosureClicked = ({ target: { name, value, checked } }) => {
    const {
      submitMatches,
      allMatches,
      leadData,
      updateLead,
      currentStep,
      trackPixel
    } = this.props;

    const { isTcpaDisclosureChecked } = this.state;

    if (checked && !isTcpaDisclosureChecked) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 4 TCPA Checkbox",
        action: "Click"
      });

      createGTMEvent({
        event: "Match Search",
        label: leadData.id,
        action: "Click",
        category: "TCPAClickedOrganic",
        value: allMatches.count
      });

      trackPixel({
        step: "4",
        eventName: pixelEventName.lead,
        leadData
      });

      const data = {
        questionName: "hasTCPAConsent",
        questionValue: "Y",
        step: currentStep
      };

      updateLead(leadData.id, data);
      submitMatches({ reference: allMatches.reference });

      this.setState({ isTcpaDisclosureChecked: true });
    }

    if (!checked) {
      createGTMEvent({
        event: "Step Conversion",
        label: "Step 4 TCPA Checkbox Unchecked",
        action: "Click"
      });
    }
  };

  render() {
    const {
      allMatches,
      isSearchingMatches,
      searchMatchesDone,
      searchMatchesFailed,
      isSearchingClickoutsMatches,
      searchClickoutsMatchesDone,
      searchClickoutsMatchesFailed,
      clickoutsMatches
    } = this.props;
    const { isTcpaDisclosureChecked } = this.state;

    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">Your Match Results</Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body">
          {!isSearchingMatches && searchMatchesDone && allMatches.count > 0 && (
            <>
              <SchoolLogoCard schools={allMatches.matches} />
              <Indicator mt={2} />
              <CheckboxField
                id="leadid_tcpa_disclosure_dyn"
                type="checkbox"
                name="tcpaDisclosure"
                label={getConsentTextForMatches(
                  allMatches && allMatches.count > 0
                    ? this.getSchoolNames(allMatches.matches)
                    : ""
                )}
                value={defaultConsentText}
                onClick={e => {
                  this.onTcpaDisclosureClicked(e);
                }}
              />
              <Box mt={2} pl={3}>
                <CustomTypography
                  fontSize="15px"
                  fontFamily="auto"
                  lineHeight="inherit"
                  letterSpacing="inherit"
                  fontWeight="inherit"
                >
                  By clicking the button below, you give your consent to be
                  called on behalf of schools that match your profile, at the
                  telephone number(s) provided with automated technology,
                  including your wireless number if provided. Consent is not
                  required to enroll in these schools.
                </CustomTypography>
              </Box>
              <Box mt={2} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onNextStepButtonClicked}
                  id="finish-button"
                  disabled={!isTcpaDisclosureChecked}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              </Box>
            </>
          )}
          {!isSearchingClickoutsMatches &&
            (searchClickoutsMatchesDone || searchClickoutsMatchesFailed) && (
              <>
                {clickoutsMatches &&
                  clickoutsMatches.schools &&
                  clickoutsMatches.schools.length > 0 && (
                    <Fragment>
                      {[...eddyClicksMatches, ...clickoutsMatches.schools].map(
                        (schoolInfo, index) => (
                          <ClickoutsSchoolCard
                            {...schoolInfo}
                            key={index}
                            searchId={clickoutsMatches.searchId}
                          />
                        )
                      )}
                    </Fragment>
                  )}
                {(!clickoutsMatches ||
                  !clickoutsMatches.schools ||
                  clickoutsMatches.schools.length < 1) && (
                  <Fragment>
                    {defaultClickoutsMatches.schools.map(
                      (schoolInfo, index) => (
                        <ClickoutsSchoolCard {...schoolInfo} key={index} />
                      )
                    )}
                  </Fragment>
                )}
              </>
            )}
          {!isSearchingMatches && searchMatchesFailed && (
            <Fragment>
              {defaultClickoutsMatches.schools.map((schoolInfo, index) => (
                <ClickoutsSchoolCard {...schoolInfo} key={index} />
              ))}
            </Fragment>
          )}
        </FormBodyContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ widget, match, lead, question }) => {
  return {
    widgetData: widget.widgetData,
    allMatches: match.allMatches,
    isSearchingMatches: match.isSearchingMatches,
    searchMatchesDone: match.searchMatchesDone,
    searchMatchesFailed: match.searchMatchesFailed,
    clickoutsMatches: match.clickoutsMatches,
    isSearchingClickoutsMatches: match.isSearchingClickoutsMatches,
    searchClickoutsMatchesDone: match.searchClickoutsMatchesDone,
    searchClickoutsMatchesFailed: match.searchClickoutsMatchesFailed,
    isSubmittingMatches: match.isSubmittingMatches,
    submitMatchesDone: match.submitMatchesDone,
    searchData: match.searchData,
    leadData: lead.leadData,
    currentStep: question.currentStep
  };
};

const mapActionsToProps = {
  goToNextStep: questionActions.goToNextStep,
  setCompletedSteps: questionActions.setCompletedSteps,
  updateLead: leadActions.updateLead,
  completeLead: leadActions.completeLead,
  searchMatches: matchActions.searchMatches,
  searchClickoutsMatches: matchActions.searchClickoutsMatches,
  submitMatches: matchActions.submitMatches,
  trackPixel: trackActions.trackPixel
};

export default connect(mapStateToProps, mapActionsToProps)(MatchStageTwo);
