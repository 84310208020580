import React from "react";

const YearOptions = ({ startYear }) => {
  const options = [];
  for (var i = startYear; i > startYear - 60; i--) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return options;
};

export default YearOptions;
