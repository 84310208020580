import * as types from "./types";

export const createLead = data => ({
  type: types.CREATE_LEAD,
  meta: {
    async: true,
    blocking: true,
    path: `/leads`,
    method: "POST",
    body: data
  }
});

export const updateLead = (id, data) => ({
  type: types.UPDATE_LEAD,
  meta: {
    async: true,
    blocking: true,
    path: `/leads/${id}`,
    method: "PUT",
    body: data
  }
});

export const completeLead = (id, data) => ({
  type: types.COMPLETE_LEAD,
  meta: {
    async: true,
    blocking: true,
    path: `/leads/${id}/complete`,
    method: "PUT",
    body: data
  }
});
