import React from "react";
import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const Wrapper = styled.div`
  color: #ffbf00;
  animation-name: ${breatheAnimation};
  animation-duration: 0.6s;
  margin-top: 7px;
`;

const BlinkText = ({ children }) => <Wrapper> {children} </Wrapper>;

export default BlinkText;
