import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Typography, Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { createGTMEvent } from "../../../utils/googleTagManager";
import {
  FormBodyContainer,
  FormHeaderContainer,
  ComplianceNotice
} from "../../components";
import SchoolCard from "./SchoolCard";
import { pixelEventName } from "../../../config";
import { trackActions } from "../../../state/track";

class MatchResults extends Component {
  state = {};

  componentDidMount() {
    const { trackPixel, leadData } = this.props;

    window.scrollTo(0, 0);

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4",
      action: "Load"
    });

    trackPixel({
      step: "4",
      eventName: pixelEventName.pageView,
      leadData
    });
  }

  onFinishButtonClicked = () => {
    const { widgetData } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4 Finish Button",
      action: "Click"
    });

    window.location.href =
      widgetData.confirmationPageUrl || widgetData.landingPageUrl;
  };

  render() {
    const { widgetData, allMatches } = this.props;

    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">Your Match Results</Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body">
          {(!allMatches || !allMatches.count) && (
            <Typography>No matches found. Please try again.</Typography>
          )}
          {allMatches && allMatches.count > 0 && (
            <Fragment>
              <Typography variant="h5">View Your Matches</Typography>
              <ComplianceNotice
                referringDomain={window.referringDomain || ""}
              />
              {allMatches.matches.map((schoolInfo, index) => (
                <SchoolCard {...schoolInfo} key={index} />
              ))}
            </Fragment>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
            align="right"
          >
            <ComplianceNotice referringDomain={window.referringDomain || ""} />
            <Button
              variant="contained"
              color="primary"
              onClick={this.onFinishButtonClicked}
              id="finish-button"
            >
              {widgetData.submitButtonText || "Finish"}
              <KeyboardArrowRight />
            </Button>
          </Box>
        </FormBodyContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ widget, match, lead }) => {
  return {
    widgetData: widget.widgetData,
    allMatches: match.allMatches,
    leadData: lead.leadData
  };
};

const mapActionsToProps = {
  trackPixel: trackActions.trackPixel
};

export default connect(mapStateToProps, mapActionsToProps)(MatchResults);
