/* eslint-disable no-useless-escape */
export const getURLParameter = name => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const getDomainFromURL = (url = "") => {
  var result;
  var match;
  if (
    (match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
    ))
  ) {
    result = match[1];
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
};

export const getReferringDomainFullURL = url => {
  const paramString = url.split("?")[1];
  const queryString = new URLSearchParams(paramString);
  let fullUrl = "";
  let refDomain = "";
  for (let pair of queryString.entries()) {
    if (pair[0] === "refURL") {
      fullUrl = pair[1];
    }
    if (pair[0] === "refDomain") {
      refDomain = "https://" + pair[1];
    }
  }
  return fullUrl ? fullUrl : refDomain;
};
