import React, { useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { matchActions } from "../../../state/match";
import { createGTMEvent } from "../../../utils/googleTagManager";
import { providers } from "../../../config";

const CardWrapper = styled.div`
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 32px;
  padding-bottom: 32px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    font-size: 14px;
    color: #888888;
    line-height: 19px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .logo-container {
    width: 100px;
    height: 100px;
    padding: 8px;
    border: 1px solid #c9c9c9;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .school-description {
    h5 {
      font-size: 17px;
      margin: 0;
      font-weight: 600;
      color: #444444;
    }

    p {
      margin-top: 10px;

      .read-more {
        cursor: pointer;
        font-weight: 700;
      }
    }
  }

  .cta_link {
    text-align: right;

    a {
      background: #eb671c;
      padding: 12px 16px 8px 16px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 4px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
`;

const Description = ({ description, url, searchId }) => {
  const [showFullText, setShowFullText] = useState(false);

  const text = description ? description.replace(/(<([^>]+)>)/gi, "") : "";
  return (
    <p>
      {text.length > 146 && !showFullText ? (
        <>
          {text.slice(0, 146)}
          {searchId && (
            <span
              className="read-more"
              onClick={() => {
                setShowFullText(true);
              }}
            >
              ...Read More
            </span>
          )}
          {!searchId && (
            /* eslint-disable */
            <a className="read-more" href={url} target="_blank" rel="noopener">
              ... Read More
            </a>
            /* eslint-enable */
          )}
        </>
      ) : text.length > 146 && showFullText ? (
        <>
          {text}
          <span
            className="read-more"
            onClick={() => {
              setShowFullText(false);
            }}
          >
            {" "}
            Show Less
          </span>
        </>
      ) : (
        text
      )}
    </p>
  );
};

const ClickoutsSchoolCard = ({
  schoolName,
  imageURL,
  description,
  url,
  searchId,
  schoolSearchId,
  submitClickoutsMatches,
  schoolTitle,
  point,
  leadData,
  isStatic = false
}) => {
  const submitSchool = (schoolSearchId, searchId, schoolName) => {
    if (searchId || isStatic) {
      createGTMEvent({
        event: "API Click Conversion",
        label: "API School Click",
        action: "Click",
        category: schoolName
      });

      if (!isStatic) {
        submitClickoutsMatches({ schoolSearchId, searchId });
      } else {
        submitClickoutsMatches({
          staticData: {
            leadId: leadData.id,
            email: leadData.email,
            widgetId: leadData.widgetId,
            selectedCategoryIds: leadData.selectedCategoryIds,
            provider:
              schoolTitle === "CollegeView"
                ? providers.neutron
                : providers.eduDynamics,
            schoolName: schoolTitle,
            schoolId: schoolSearchId,
            point: point
          }
        });
      }
    }
  };

  return (
    <CardWrapper className={searchId ? "clicks-school-wrapper" : ""}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          {/* eslint-disable  */}
          <a
            onClick={() => {
              submitSchool(schoolSearchId, searchId, schoolName);
            }}
            href={url}
            target="_blank"
            rel="noopener"
          >
            {/* eslint-enable  */}
            <div className="logo-container">
              <img src={imageURL} alt={schoolName} />
            </div>
          </a>
        </Grid>
        <Grid item xs={6} sm={8}>
          <div className="school-description">
            <h5>
              {/* eslint-disable  */}
              <a
                onClick={() => {
                  submitSchool(schoolSearchId, searchId, schoolName);
                }}
                href={url}
                target="_blank"
                rel="noopener"
              >
                {schoolName}
              </a>
              {/* eslint-enable  */}
            </h5>
            {searchId && <span className="school-star"></span>}
            <Description
              description={description}
              url={url}
              schoolSearchId={schoolSearchId}
              searchId={searchId}
            />
          </div>
        </Grid>
        <Grid item sm={6}>
          <p>Sponsored Results</p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="cta_link">
            {/* eslint-disable  */}
            <a
              onClick={() => {
                submitSchool(schoolSearchId, searchId, schoolName);
              }}
              href={url}
              target="_blank"
              rel="noopener"
            >
              Get Free Info
            </a>
            {/* eslint-enable  */}
          </div>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

const mapStateToProps = ({ lead }) => {
  return {
    leadData: lead.leadData
  };
};

const mapActionsToProps = {
  submitClickoutsMatches: matchActions.submitClickoutsMatches
};

export default connect(mapStateToProps, mapActionsToProps)(ClickoutsSchoolCard);
