export const SEARCH_MATCHES = "match/search";
export const SEARCH_MATCHES_DONE = "match/search_done";
export const SEARCH_MATCHES_FAILED = "match/search_failed";

export const SUBMIT_MATCHES = "match/submit";
export const SUBMIT_MATCHES_DONE = "match/submit_done";
export const SUBMIT_MATCHES_FAILED = "match/submit_failed";

export const SEARCH_CLICKOUTS_MATCHES = "match/clickouts_search";
export const SEARCH_CLICKOUTS_MATCHES_DONE = "match/clickouts_search_done";
export const SEARCH_CLICKOUTS_MATCHES_FAILED = "match/clickouts_search_failed";

export const SUBMIT_CLICKOUTS_MATCHES = "match/clickouts_submit";
export const SUBMIT_CLICKOUTS_MATCHES_DONE = "match/clickouts_submit_done";
export const SUBMIT_CLICKOUTS_MATCHES_FAILED = "match/clickouts_submit_failed";

export const RESET_MATCH_SEARCH = "match/reset_search";
