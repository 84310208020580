import * as types from "./types";

const initialState = {};

const trackReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.TRACK_PIXEL:
      return {
        ...state,
        isTrackingPixel: true,
        trackPixelDone: false,
        trackPixelFailed: false
      };
    case types.TRACK_PIXEL_DONE:
      return {
        ...state,
        isTrackingPixel: false,
        trackPixelDone: true,
        trackPixelFailed: false
      };
    case types.TRACK_PIXEL_FAILED:
      return {
        ...state,
        isTrackingPixel: false,
        trackPixelDone: false,
        trackPixelFailed: true
      };
    default:
      return state;
  }
};

export default trackReducers;
