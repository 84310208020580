import React, { useState } from "react";
import styled from "styled-components";
import Image from "react-shimmer";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from "@material-ui/core";
import SchoolDescription from '../../components/SchoolDescription';

const CardWrapper = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 20px 0px;

  &:last-child {
    border: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  h5 {
    font-size: 16px;
    margin: 0;
  }

  h5.show-description,
  p.show-description {
    cursor: pointer;
  }

  p.show-description {
    color: blue;
    margin: 8px 0;
  }
`;

const SchoolCard = ({ schoolName, schoolLogoUrl, schoolDescription, schoolId }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  function onOpenButtonClick() {
    setIsOpenDialog(true);
  }

  function onCloseButtonClick() {
    setIsOpenDialog(false);
  }

  return (
    <CardWrapper>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <Image
            src={schoolLogoUrl}
            width={130}
            height={36}
            alt={schoolName}
            style={{ objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={6} sm={8}>
          <h5 className="show-description" onClick={onOpenButtonClick}>
            {schoolName}
          </h5>
        </Grid>
        <Grid item sm={4}></Grid>
        <Grid item xs={12} sm={8}>
          <p className="show-description" onClick={onOpenButtonClick}>
            View School Description
          </p>
        </Grid>
      </Grid>
      <Dialog
        open={isOpenDialog}
        onClose={onCloseButtonClick}
        aria-labelledby="school-info"
        aria-describedby="school-description"
      >
        <DialogTitle id="school-info">{schoolName}</DialogTitle>
        <DialogContent>
          <DialogContentText id="school-description">
            <SchoolDescription id={schoolId} description={schoolDescription} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseButtonClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </CardWrapper>
  );
};

export default SchoolCard;
