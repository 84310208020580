const updateStepThreeLeadData = ({
  leadData,
  updateLead,
  values,
  currentStep
}) => {
  if (!leadData.firstName) {
    const firstNameData = {
      questionName: "firstName",
      questionValue: values.firstName,
      step: currentStep
    };
    updateLead(leadData.id, firstNameData);
  }

  if (!leadData.lastName) {
    const lastNameData = {
      questionName: "lastName",
      questionValue: values.lastName,
      step: currentStep
    };
    updateLead(leadData.id, lastNameData);
  }

  if (!leadData.phone) {
    const phoneNumberData = {
      questionName: "phone",
      questionValue: values.phone,
      step: currentStep
    };
    updateLead(leadData.id, phoneNumberData);
  }

  if (!leadData.email) {
    const emailData = {
      questionName: "email",
      questionValue: values.email,
      step: currentStep
    };
    updateLead(leadData.id, emailData);
  }
  return;
};

export default updateStepThreeLeadData;
