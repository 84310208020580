import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

const ErrorPage = ({ location }) => {
  const queries = new URLSearchParams(location.search);
  const message = queries.get("message");
  const redirectUrl = queries.get("redirectUrl");

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ minHeight: "90vh" }}
    >
      <Grid item xs={12}>
        {redirectUrl ? (
          <Typography align="center">
            {message} Please Go <a href={redirectUrl}>back</a> to previous page
            and try again.
          </Typography>
        ) : (
          <Typography align="center">{message}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default withRouter(ErrorPage);
