import styled from "styled-components";
import { typography, space, color, position, layout } from "styled-system";
import { Typography } from "@material-ui/core";

const CustomTypography = styled(Typography)`
  && {
    ${typography};
    ${space};
    ${color};
    ${position};
    ${layout};
  }
`;

export default CustomTypography;
