import React from "react";
import { Route, Switch } from "react-router-dom";
import { withAuth } from "../services";

const PrivateRoutes = () => (
  <Switch>
    <Route
      exact
      path="/private"
      component={withAuth(<div>This is a private route</div>)}
    />
  </Switch>
);

export default PrivateRoutes;
