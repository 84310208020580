import React from "react";
import styled from "styled-components";
import { color } from "styled-system";
import { CircularProgress, Typography } from "@material-ui/core";

const LoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 123;
  overflow: hidden;

  ${color};

  .seconadry-loader,
  .loading-message {
    position: fixed;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    font-size: 18px;
  }

  .seconadry-loader.with-message {
    top: calc(50% - 30px);
  }

  .loading-message {
    top: calc(50% + 30px);
    left: 50%;
    transform: translateX(-50%);
  }

  .MuiCircularProgress-colorPrimary {
    color: #0b7fbf;
  }
`;

const FullPageLoader = ({ loadingMessage, ...props }) => {
  return (
    <LoaderContainer {...props}>
      <CircularProgress
        className={`seconadry-loader ${loadingMessage ? "with-message" : ""}`}
      ></CircularProgress>
      <Typography align="center" className="loading-message">
        {loadingMessage}
      </Typography>
    </LoaderContainer>
  );
};

export default FullPageLoader;
