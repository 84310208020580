import styled from "styled-components";

const FormBodyContainer = styled.div`
  padding: 10px 30px 30px 30px;
  background-color: #f7f7f7;
  border-radius: 0px 0px 4px 4px;
  border-bottom: 3px solid #eaeaea;
`;

export default FormBodyContainer;
