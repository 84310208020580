import * as types from "./types";

const initialState = {
  isFetchingWidgetById: false,
  fetchWidgetByIdDone: false,
  fetchWidgetByIdFailed: false
};

const widgetReducers = function(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_WIDGET_BY_ID:
      return {
        ...state,
        isFetchingWidgetById: true,
        fetchWidgetByIdDone: false,
        fetchWidgetByIdFailed: false
      };
    case types.FETCH_WIDGET_BY_ID_DONE:
      return {
        ...state,
        isFetchingWidgetById: false,
        fetchWidgetByIdDone: true,
        fetchWidgetByIdFailed: false,
        widgetData: payload.data
      };
    case types.FETCH_WIDGET_BY_ID_FAILED:
      return {
        ...state,
        isFetchingWidgetById: false,
        fetchWidgetByIdDone: false,
        fetchWidgetByIdFailed: true
      };
    default:
      return state;
  }
};

export default widgetReducers;
