export const FETCH_ALL_QUESTIONS = "question/fetch_all";
export const FETCH_ALL_QUESTIONS_DONE = "question/fetch_all_done";
export const FETCH_ALL_QUESTIONS_FAILED = "question/fetch_all_failed";

export const SEARCH_FEED_OPTIONS = "question/search_feed_options";
export const SEARCH_FEED_OPTIONS_DONE = "question/search_feed_options_done";
export const SEARCH_FEED_OPTIONS_FAILED = "question/search_feed_options_failed";

export const GO_TO_NEXT_STEP = "question/go_to_next_step";
export const GO_TO_PREVIOUS_STEP = "question/go_to_previous_step";
export const SET_COMPLETED_STEPS = "question/set_completed_steps";
export const GO_TO_STAGE_TWO_SEARCH = "question/go_to_stage_two_search";
