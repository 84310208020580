import React from "react";
import styled from "styled-components";
const size = {
  mobile: '600px'
}
export const device = {
  mobile: `(max-width: ${size.mobile})`
}
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  div:nth-child(1) {
    width: 250px;
  }
  div:nth-child(2) {
    margin: 0 10px;
    width: 100% !important;
  }
  div:nth-child(3) {
    width: 250px;
  }
  @media ${device.mobile} {
    div:nth-child(1) {
      width: 150px;
    }
    div:nth-child(3) {
      width: 150px;
    }
  }
`;

const CSZWrapper = ({ className, children, ...props }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

export default CSZWrapper;