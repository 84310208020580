import * as types from "./types";
import { v4 as uuidV4 } from "uuid";
import { createFbpEvent } from "../../utils/fbPixel";
import { getCookie } from "../../utils/cookie";

export const trackPixel = data => {
  const uuid = uuidV4();
  const userCookie = getCookie();
  if (userCookie) {
    data["userCookie"] = userCookie;
  }

  createFbpEvent({ eventId: uuid, ...data });

  return {
    type: types.TRACK_PIXEL,
    meta: {
      async: true,
      blocking: true,
      path: `/tracks/pixel`,
      method: "POST",
      body: {
        url: window ? window.location.href : "",
        eventId: uuid,
        ...data
      }
    }
  };
};

export const trackSnapchatPixel = data => {
  const uuid = uuidV4();
  const userCookie = getCookie();
  if (userCookie) {
    data["userCookie"] = userCookie;
  }
  const currentTimeStamp = new Date().getTime();
  return {
    type: types.TRACK_PIXEL,
    meta: {
      async: true,
      blocking: true,
      path: `/tracks/snapchat`,
      method: "POST",
      body: {
        url: window ? window.location.href : "",
        eventId: uuid,
        clientDedUpId: userCookie,
        timeStamp: currentTimeStamp,
        ...data
      }
    }
  };
};
