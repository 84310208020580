import styled from "styled-components";

const FormHeaderContainer = styled.div`
  padding: 10px 20px;
  color: #fff;
  font-size: 24px;
  background-color: #a7a7a7;
  border-bottom: 4px solid #e7e7e7;
  border-radius: 4px 4px 0px 0px;
`;

export default FormHeaderContainer;
