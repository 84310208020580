import React from "react";
import styled from "styled-components";

const Wrapper = styled.button`
  border: none;
  margin-left: 5px;
  padding: 6px 8px;
  border-radius: 5px;
  line-height: 0.8;

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ChangeButton = ({ className, children, ...props }) => (
  <Wrapper className={className}>
    <span {...props}>{children}</span>
  </Wrapper>
);

export default ChangeButton;