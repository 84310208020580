import * as types from "./types";

const initialState = {
  leadData: {}
};

const leadReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.CREATE_LEAD:
      return {
        ...state,
        isCreatingLead: true,
        createLeadDone: false,
        createLeadFailed: false
      };
    case types.CREATE_LEAD_DONE:
      return {
        ...state,
        isCreatingLead: false,
        createLeadDone: true,
        createLeadFailed: false,
        leadData: payload.data
      };
    case types.CREATE_LEAD_FAILED:
      return {
        ...state,
        isCreatingLead: false,
        createLeadDone: false,
        createLeadFailed: true
      };
    case types.UPDATE_LEAD:
      return {
        ...state,
        isUpdatingLead: true,
        updateLeadDone: false,
        updateLeadFailed: false
      };
    case types.UPDATE_LEAD_DONE:
      return {
        ...state,
        isUpdatingLead: false,
        updateLeadDone: true,
        updateLeadFailed: false,
        leadData: payload.data
      };
    case types.UPDATE_LEAD_FAILED:
      return {
        ...state,
        isUpdatingLead: false,
        updateLeadDone: false,
        updateLeadFailed: true
      };
    case types.COMPLETE_LEAD:
      return {
        ...state,
        isCompletingLead: true,
        completeLeadDone: false,
        completeLeadFailed: false
      };
    case types.COMPLETE_LEAD_DONE:
      return {
        ...state,
        isCompletingLead: false,
        completeLeadDone: true,
        completeLeadFailed: false,
        leadData: payload.data
      };
    case types.COMPLETE_LEAD_FAILED:
      return {
        ...state,
        isCompletingLead: false,
        completeLeadDone: false,
        completeLeadFailed: true
      };
    default:
      return state;
  }
};

export default leadReducers;
