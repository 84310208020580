import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/HighlightOff";

const DialogExtended = ({ open, handleClose, heading, children }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      {heading && (
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {heading}
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogExtended;
