import React from "react";
import { FormField } from "../../components";
import { validateRequiredFiled } from "../../../utils/validator";

const ChildQuestionField = ({
  childQuestion,
  touched,
  errors,
  handleOnBlur,
  ...props
}) => {
  if (childQuestion.type === "text") {
    return (
      <FormField
        type="text"
        name={`${childQuestion.metaKey}`}
        label={childQuestion.label}
        placeholder={childQuestion.label}
        validate={validateRequiredFiled}
        touched={touched[`${childQuestion.metaKey}`]}
        error={errors[`${childQuestion.metaKey}`]}
        onBlur={e => {
          handleOnBlur(e, childQuestion.label);
        }}
        {...props}
      />
    );
  }
  return (
    <FormField
      name={`${childQuestion.metaKey}`}
      label={childQuestion.label}
      component="select"
      validate={validateRequiredFiled}
      touched={touched[`${childQuestion.metaKey}`]}
      error={errors[`${childQuestion.metaKey}`]}
      onBlur={e => {
        handleOnBlur(e, childQuestion.metaKey);
      }}
      {...props}
    >
      <option value="">- Select -</option>
      {childQuestion.options.length &&
        childQuestion.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text || option.name}
          </option>
        ))}
    </FormField>
  );
};

export default ChildQuestionField;
