import React from "react";

const SchoolDescription = ({ id, description }) => {
  if (id === "INDEPENU")
    return (
      <div>
        <h2>
          The Secret to Getting Ahead is Getting <em>Started</em>
        </h2>

        <p>
          With a mission of helping students graduate, launch better careers,
          and gain greater independence to live the life they want as soon as
          possible, Independence University offers flexible fully online degree
          programs. Program options are available in healthcare, business*,
          information technology, and graphic arts. The programs are
          career-focused to help ensure the knowledge and skills you learn match
          what today's employers are looking for.
        </p>

        <p>*Business programs full – not accepting enrollments.</p>

        <p>
          At Independence University, instructors provide you with individual
          attention, including personalized tutoring and advising support
          throughout your college experience. You'll get the help you need to
          succeed in your coursework, expand your professional network, and
          launch your career.
        </p>

        <h4>We want to help you get that degree. Consider: </h4>
        <ol>
          <li>
            <strong>Start Fast, Finish Fast:</strong> You can earn an
            Associate’s degree in as few as 19 months, and a Bachelor’s degree
            in as few as 36 months, and a Master's degree in as few as 15
            months.
          </li>
          <li>
            <strong>Tuition Assistance:</strong> Financial aid and grants are
            available to those who qualify.<sup>1</sup>
          </li>
          <li>
            <strong>No additional Fees, No Hassles:</strong> No parking fees, no
            long lines, no lab fees, no cost for books, no waiting for class
            availability.
          </li>
          <li>
            <strong>Employment Assistance:</strong> We'll help you find your new
            job when you graduate.<sup>2</sup>
          </li>
          <li>
            <strong>Career-focused Curriculum:</strong> You'll receive practical
            instruction for work-related knowledge.
          </li>
          <li>
            <strong>Convenience:</strong> Online classes are available to fit
            your schedule.
          </li>
          <li>
            <strong>Accredited Institution:</strong> Accredited by Accrediting
            Commission of Career Schools and Colleges, (ACCSC).
          </li>
          <li>
            <strong>Programs Approved for Veterans:</strong> Take full advantage
            of your military benefits to earn your degree.
          </li>
          <li>
            <strong>A New Tablet and Laptop Computer:</strong> A New Tablet and
            Laptop Computer: Yours to use in school and keep when you graduate.
            <sup>3</sup>
          </li>
        </ol>
        <p>
          Since every student is different, there isn't a "one size fits all"
          for school information. Start dates and tuition often vary between
          selected programs, and financial aid opportunities are unique to each
          student's circumstances.
        </p>

        <p>
          We invest in you! Independence University is a nonprofit institution
          and an accredited member, ACCSC.
        </p>

        <p>
          <sup>1</sup>Grants and scholarship awards are limited and only
          available to those who qualify. See www.scholarshipiu.com for details.
        </p>

        <p>
          <sup>2</sup>The college does not guarantee a job. Gaining employment
          is the graduate's responsibility.
        </p>

        <p>
          <sup>3</sup>Receive a tablet during your first module (four-week term)
          and a laptop when you begin your fourth module.
        </p>

        <p>
          Independence University admits students of any race, color, and
          national or ethnic origin.
        </p>

        <p>
          The Bachelor of Science Completion Nursing degree program at
          Independence University is accredited by the Commission on Collegiate
          Nursing Education (www.ccneaccreditation.org).
        </p>
      </div>
    );

  return description;
};

export default SchoolDescription;
