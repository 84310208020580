import React from "react";
import styled from "styled-components";
import { space } from "styled-system";
import { indicatorColorCodes } from "../../config";

const Wrapper = styled.div`
  .arrow {
    height: 20px;
    width: 25px;
    margin-left: -6px;
  }
  .direction-text {
    padding-top: -25px;
    font-size: 17px;
    color: blue;
    margin-left: 22px;
    margin-top: -18px;
  }

  .text-md {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .text-lg {
      display: none;
    }

    .text-md {
      display: block;
    }
  }

  ${space};
`;

const Indicator = ({ className, children, ...props }) => (
  <Wrapper className={className} {...props}>
    <div className="arrow">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 32.45 49.01" style={{paddingTop: '8px'}}>
        <path
          style={{
            fill: indicatorColorCodes[props.referringDomain]
          }}
          d="M24.88,6.8H12.56v34.58l-2.84-2.94c-0.38-0.4-1.02-0.41-1.41-0.03s-0.41,1.02-0.03,1.41l4.56,4.73
           c0,0,0.01,0,0.01,0.01c0.18,0.18,0.43,0.3,0.71,0.3s0.53-0.12,0.71-0.3c0,0,0.01,0,0.01-0.01l4.56-4.73
           c0.38-0.4,0.37-1.03-0.03-1.41c-0.4-0.38-1.03-0.37-1.41,0.03v0l-2.84,2.94V8.8h10.32c0.55,0,1-0.45,1-1
           C25.88,7.25,25.43,6.8,24.88,6.8z"
        ></path>
      </svg>
    </div>
    <div className="direction-text text-lg">
      Click the checkbox. Get matched.
    </div>
    <div className="direction-text text-md">
      Click the checkbox. Get matched.
    </div>
  </Wrapper>
);

export default Indicator;
