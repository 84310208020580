import React from "react";
import { Grid, Box, Container } from "@material-ui/core";
import { RawHTML, FullPageLoader } from "../components/";

const TwoColumnRightSidebar = ({
  header,
  sidebar,
  footer,
  children,
  isPageLoading,
  isDataLoading,
  loadingMessage
}) => (
  <>
    {isPageLoading ? (
      <FullPageLoader loadingMessage={loadingMessage} />
    ) : (
      <>
        {isDataLoading && <FullPageLoader loadingMessage={loadingMessage} />}
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box component="div" style={{ minHeight: header ? "60px" : "" }}>
                {header && <RawHTML html={header} mt={20} />}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {children}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <RawHTML html={sidebar} />
            </Grid>
            {footer && (
              <Grid item xs={12}>
                <RawHTML html={footer} />
              </Grid>
            )}
          </Grid>
        </Container>
      </>
    )}
  </>
);

export default TwoColumnRightSidebar;
